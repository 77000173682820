import { reportNavType } from '@edulastic/constants/const/report'
import API from '../utils/API'
import { paramsSerializer, prefix } from './utils'

const api = new API()

const { DW_DOMAIN_ANALYSIS_REPORT } = reportNavType

const getDomainAnalysisSummary = (params) => {
  return api
    .callApi({
      useSlowApi: true,
      url: `${prefix}/${DW_DOMAIN_ANALYSIS_REPORT}/summary`,
      params,
      paramsSerializer,
    })
    .then((result) => result.data)
}

const getDomainAnalysisDetails = (params) => {
  return api
    .callApi({
      useSlowApi: true,
      url: `${prefix}/${DW_DOMAIN_ANALYSIS_REPORT}/details`,
      params,
      paramsSerializer,
    })
    .then((result) => result.data)
}

const getSkillInfo = (params) => {
  return api
    .callApi({
      useSlowApi: true,
      url: `${prefix}/${DW_DOMAIN_ANALYSIS_REPORT}/skill-info`,
      params,
      paramsSerializer,
    })
    .then((r) => r.data)
}

export default {
  getDomainAnalysisSummary,
  getDomainAnalysisDetails,
  getSkillInfo,
}
