module.exports = {
  IMAGES: 'image/*',
  DOC: 'application/msword',
  DOCX:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ZIP: 'application/zip',
  SWF: 'application/x-shockwave-flash',
  PPTX:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PPT: 'application/vnd.ms-powerpoint',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  PNG: 'image/png',
  GIF: 'image/gif',
  HTML: 'text/html',
  MP3: 'audio/mpeg',
  MP4: 'video/mp4',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF: 'application/pdf',
  XLS: 'application/vnd.ms-excel',
}
