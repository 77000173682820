import { CALC_MODES } from '../../../../src/client/assessment/themes/common/Calculators/constants'

const standardGraphing = {
  actions: true,
  keypad: true,
  graphpaper: true,
  expressions: true,
  settingsMenu: true,
  zoomButtons: true,
  expressionsTopbar: true,
  pointsOfInterest: true,
  trace: true,
  border: true,
  lockViewport: false,
  expressionsCollapsed: false,
  capExpressionSize: false,
  administerSecretFolders: false,
  images: true,
  folders: true,
  notes: true,
  links: true,
  qwertyKeyboard: true,
  restrictedFunctions: false,
  pasteGraphLink: false,
  pasteTableData: true,
  clearIntoDegreeMode: false,
  autosize: true,
  plotSingleVariableImplicitEquations: true,
  projectorMode: false,
  decimalToFraction: true,
  invertedColors: false,
  degreeMode: false,
  showGrid: true,
  polarMode: false,
  showXAxis: true,
  showYAxis: true,
  xAxisNumbers: true,
  yAxisNumbers: true,
  polarNumbers: true,
  logScales: true,
}

const stateConfigGraphing = {
  alabama: standardGraphing,
  alaska: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    logScales: false,
  },
  arizona: standardGraphing,
  arkansas: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    zoomFit: true,
    qwertyKeyboard: false,
    degreeMode: true,
    brailleControls: false,
    pasteTableData: false,
    plotSingleVariableImplicitEquations: false,
    actions: false,
    logScales: false,
  },
  california: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
  },
  connecticut: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
  },
  delaware: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotSingleVariableImplicitEquations: false,
    actions: false,
  },
  florida: standardGraphing,
  georgia: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    sliders: false,
    substitutions: false,
    logScales: false,
  },
  hawaii: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
  },
  idaho: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
  },
  indiana: standardGraphing,
  iowa: standardGraphing,
  kentucky: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    degreeMode: true,
    logScales: false,
  },
  louisiana: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    plotSingleVariableImplicitEquations: false,
    actions: false,
    sliders: false,
    substitutions: false,
    logScales: false,
  },
  maine: {
    ...standardGraphing,
    logScales: false,
  },
  maryland: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    degreeMode: true,
    plotSingleVariableImplicitEquations: false,
    logScales: false,
  },
  massachusetts: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    degreeMode: true,
    plotSingleVariableImplicitEquations: false,
    logScales: false,
  },
  michigan: standardGraphing,
  mississippi: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    substitutions: false,
    logScales: false,
  },
  missouri: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    logScales: false,
  },
  montana: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotSingleVariableImplicitEquations: false,
    actions: false,
  },
  nebraska: standardGraphing,
  nevada: {
    ...standardGraphing,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  new_hampshire: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    zoomFit: true,
    qwertyKeyboard: false,
    degreeMode: true,
    plotSingleVariableImplicitEquations: false,
    logScales: false,
  },
  new_york: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
  },
  north_carolina: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    plotSingleVariableImplicitEquations: false,
    actions: false,
    substitutions: false,
    logScales: false,
    forceLogModeRegressions: true,
  },
  north_dakota: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    zoomFit: true,
    qwertyKeyboard: false,
    degreeMode: true,
    plotSingleVariableImplicitEquations: false,
    logScales: false,
  },
  ohio: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    zoomFit: true,
    qwertyKeyboard: false,
    degreeMode: true,
    plotSingleVariableImplicitEquations: false,
    logScales: false,
  },
  oklahoma: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
  },
  oregon: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
  },
  pennsylvania: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    plotImplicits: false,
    plotSingleVariableImplicitEquations: false,
    sliders: false,
    actions: false,
    substitutions: false,
    logScales: false,
  },
  rhode_island: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
    logScales: false,
  },
  south_carolina: {
    ...standardGraphing,
    sliders: false,
    actions: false,
    substitutions: false,
    logScales: false,
    forceLogModeRegressions: true,
  },
  south_dakota: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
  },
  tennessee: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    degreeMode: true,
    plotSingleVariableImplicitEquations: false,
    logScales: false,
    forceLogModeRegressions: true,
  },
  texas: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    degreeMode: true,
    sliders: false,
    plotImplicits: false,
    plotInequalities: false,
    logScales: false,
  },
  utah: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    degreeMode: true,
    logScales: false,
  },
  vermont: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    zoomFit: true,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
    actions: false,
    logScales: false,
  },
  virginia: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    degreeMode: true,
    plotSingleVariableImplicitEquations: false,
    logScales: false,
  },
  washington: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
  },
  west_virginia: standardGraphing,
  wyoming: {
    ...standardGraphing,
    images: false,
    folders: false,
    notes: false,
    zoomFit: true,
    qwertyKeyboard: false,
    degreeMode: true,
  },
}

const standardScientific = {
  authorFeatures: true,
  decimalToFraction: true,
  qwertyKeyboard: true,
}

const stateConfigScientific = {
  alabama: {
    ...standardScientific,
    authorFeatures: false,
  },
  alaska: {
    ...standardScientific,
    authorFeatures: false,
    qwertyKeyboard: false,
    decimalToFraction: false,
  },
  arizona: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
  },
  arkansas: {
    ...standardScientific,
    authorFeatures: false,
    qwertyKeyboard: false,
  },
  california: {
    ...standardScientific,
    images: false,
    folders: false,
    notes: false,
    qwertyKeyboard: false,
    degreeMode: true,
    decimalToFraction: false,
    plotInequalities: false,
    plotSingleVariableImplicitEquations: false,
  },
  connecticut: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  delaware: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  florida: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  georgia: {
    ...standardScientific,
    authorFeatures: false,
    qwertyKeyboard: false,
  },
  hawaii: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  idaho: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  indiana: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  iowa: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
  },
  kentucky: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
  },
  louisiana: standardScientific,
  maine: standardScientific,
  maryland: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
  },
  massachusetts: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
  },
  michigan: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    brailleControls: false,
  },
  mississippi: standardScientific,
  missouri: {
    ...standardScientific,
    authorFeatures: false,
  },
  montana: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  nebraska: standardScientific,
  nevada: standardScientific,
  new_hampshire: {
    ...standardScientific,
    authorFeatures: false,
    qwertyKeyboard: false,
  },
  new_york: standardScientific,
  north_carolina: {
    ...standardScientific,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  north_dakota: {
    ...standardScientific,
    authorFeatures: false,
    qwertyKeyboard: false,
  },
  ohio: {
    ...standardScientific,
    authorFeatures: false,
    qwertyKeyboard: false,
  },
  oklahoma: standardScientific,
  oregon: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  pennsylvania: {
    ...standardScientific,
    authorFeatures: false,
  },
  rhode_island: standardScientific,
  south_carolina: standardScientific,
  south_dakota: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  tennessee: {
    ...standardScientific,
    authorFeatures: false,
  },
  texas: {
    ...standardScientific,
    authorFeatures: false,
  },
  utah: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
  },
  vermont: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  virginia: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
  },
  washington: {
    ...standardScientific,
    authorFeatures: false,
    decimalToFraction: false,
    qwertyKeyboard: false,
  },
  west_virginia: {
    ...standardScientific,
    authorFeatures: false,
    qwertyKeyboard: false,
  },
  wyoming: {
    ...standardScientific,
    authorFeatures: false,
    qwertyKeyboard: false,
  },
}

const standardBasic = { additionalFunctions: [] }

const stateConfigBasic = {
  alabama: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
    decimalToFraction: true,
  },
  alaska: standardBasic,
  arizona: standardBasic,
  arkansas: standardBasic,
  california: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  connecticut: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  delaware: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  florida: standardBasic,
  georgia: standardBasic,
  hawaii: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  idaho: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  indiana: standardBasic,
  iowa: standardBasic,
  kentucky: standardBasic,
  louisiana: standardBasic,
  maine: standardBasic,
  maryland: standardBasic,
  massachusetts: standardBasic,
  michigan: {
    ...standardBasic,
    brailleControls: false,
    additionalFunctions: ['fraction'],
  },
  mississippi: standardBasic,
  missouri: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  montana: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  nebraska: standardBasic,
  nevada: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  new_hampshire: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  new_york: standardBasic,
  north_carolina: {
    ...standardBasic,
    decimalToFraction: true,
    additionalFunctions: ['fraction'],
  },
  north_dakota: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  ohio: standardBasic,
  oklahoma: standardBasic,
  oregon: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  pennsylvania: standardBasic,
  rhode_island: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  south_carolina: standardBasic,
  south_dakota: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  tennessee: standardBasic,
  texas: standardBasic,
  utah: standardBasic,
  vermont: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  virginia: standardBasic,
  washington: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  west_virginia: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
  wyoming: {
    ...standardBasic,
    additionalFunctions: ['fraction'],
  },
}

const allStates = [
  {
    key: 'alabama',
    name: 'Alabama',
    abbreviation: 'AL',
    t_abbreviation: 'Ala.',
  },
  {
    key: 'alaska',
    name: 'Alaska',
    abbreviation: 'AK',
    t_abbreviation: 'Alaska',
  },
  {
    key: 'arizona',
    name: 'Arizona',
    abbreviation: 'AZ',
    t_abbreviation: 'Ariz.',
  },
  {
    key: 'arkansas',
    name: 'Arkansas',
    abbreviation: 'AR',
    t_abbreviation: 'Ark.',
  },
  {
    key: 'california',
    name: 'California',
    abbreviation: 'CA',
    t_abbreviation: 'Calif.',
  },
  {
    key: 'colorado',
    name: 'Colorado',
    abbreviation: 'CO',
    t_abbreviation: 'Colo.',
  },
  {
    key: 'connecticut',
    name: 'Connecticut',
    abbreviation: 'CT',
    t_abbreviation: 'Conn.',
  },
  {
    key: 'delaware',
    name: 'Delaware',
    abbreviation: 'DE',
    t_abbreviation: 'Del.',
  },
  {
    key: 'florida',
    name: 'Florida',
    abbreviation: 'FL',
    t_abbreviation: 'Fla.',
  },
  {
    key: 'georgia',
    name: 'Georgia',
    abbreviation: 'GA',
    t_abbreviation: 'Ga.',
  },
  {
    key: 'hawaii',
    name: 'Hawaii',
    abbreviation: 'HI',
    t_abbreviation: 'Hawaii',
  },
  {
    key: 'idaho',
    name: 'Idaho',
    abbreviation: 'ID',
    t_abbreviation: 'Idaho',
  },
  {
    key: 'illinois',
    name: 'Illinois',
    abbreviation: 'IL',
    t_abbreviation: 'Ill.',
  },
  {
    key: 'indiana',
    name: 'Indiana',
    abbreviation: 'IN',
    t_abbreviation: 'Ind.',
  },
  {
    key: 'iowa',
    name: 'Iowa',
    abbreviation: 'IA',
    t_abbreviation: 'Iowa',
  },
  {
    key: 'kansas',
    name: 'Kansas',
    abbreviation: 'KS',
    t_abbreviation: 'Kans.',
  },
  {
    key: 'kentucky',
    name: 'Kentucky',
    abbreviation: 'KY',
    t_abbreviation: 'Ky.',
  },
  {
    key: 'louisiana',
    name: 'Louisiana',
    abbreviation: 'LA',
    t_abbreviation: 'La.',
  },
  {
    key: 'maine',
    name: 'Maine',
    abbreviation: 'ME',
    t_abbreviation: 'Maine',
  },
  {
    key: 'maryland',
    name: 'Maryland',
    abbreviation: 'MD',
    t_abbreviation: 'Md.',
  },
  {
    key: 'massachusetts',
    name: 'Massachusetts',
    abbreviation: 'MA',
    t_abbreviation: 'Mass.',
  },
  {
    key: 'michigan',
    name: 'Michigan',
    abbreviation: 'MI',
    t_abbreviation: 'Mich.',
  },
  {
    key: 'minnesota',
    name: 'Minnesota',
    abbreviation: 'MN',
    t_abbreviation: 'Minn.',
  },
  {
    key: 'mississippi',
    name: 'Mississippi',
    abbreviation: 'MS',
    t_abbreviation: 'Miss.',
  },
  {
    key: 'missouri',
    name: 'Missouri',
    abbreviation: 'MO',
    t_abbreviation: 'Mo.',
  },
  {
    key: 'montana',
    name: 'Montana',
    abbreviation: 'MT',
    t_abbreviation: 'Mont.',
  },
  {
    key: 'nebraska',
    name: 'Nebraska',
    abbreviation: 'NE',
    t_abbreviation: 'Neb. or Nebr.',
  },
  {
    key: 'nevada',
    name: 'Nevada',
    abbreviation: 'NV',
    t_abbreviation: 'Nev.',
  },
  {
    key: 'new_hampshire',
    name: 'New Hampshire',
    abbreviation: 'NH',
    t_abbreviation: 'N.H.',
  },
  {
    key: 'new_jersey',
    name: 'New Jersey',
    abbreviation: 'NJ',
    t_abbreviation: 'N.J.',
  },
  {
    key: 'new_mexico',
    name: 'New Mexico',
    abbreviation: 'NM',
    t_abbreviation: 'N.Mex.',
  },
  {
    key: 'new_york',
    name: 'New York',
    abbreviation: 'NY',
    t_abbreviation: 'N.Y.',
  },
  {
    key: 'north_carolina',
    name: 'North Carolina',
    abbreviation: 'NC',
    t_abbreviation: 'N.C.',
  },
  {
    key: 'north_dakota',
    name: 'North Dakota',
    abbreviation: 'ND',
    t_abbreviation: 'N.Dak.',
  },
  {
    key: 'ohio',
    name: 'Ohio',
    abbreviation: 'OH',
    t_abbreviation: 'Ohio',
  },
  {
    key: 'oklahoma',
    name: 'Oklahoma',
    abbreviation: 'OK',
    t_abbreviation: 'Okla.',
  },
  {
    key: 'oregon',
    name: 'Oregon',
    abbreviation: 'OR',
    t_abbreviation: 'Ore.',
  },
  {
    key: 'pennsylvania',
    name: 'Pennsylvania',
    abbreviation: 'PA',
    t_abbreviation: 'Pa.',
  },
  {
    key: 'rhode_island',
    name: 'Rhode Island',
    abbreviation: 'RI',
    t_abbreviation: 'R.I.',
  },
  {
    key: 'south_carolina',
    name: 'South Carolina',
    abbreviation: 'SC',
    t_abbreviation: 'S.C.',
  },
  {
    key: 'south_dakota',
    name: 'South Dakota',
    abbreviation: 'SD',
    t_abbreviation: 'S.Dak.',
  },
  {
    key: 'tennessee',
    name: 'Tennessee',
    abbreviation: 'TN',
    t_abbreviation: 'Tenn.',
  },
  {
    key: 'texas',
    name: 'Texas',
    abbreviation: 'TX',
    t_abbreviation: 'Tex.',
  },
  {
    key: 'utah',
    name: 'Utah',
    abbreviation: 'UT',
    t_abbreviation: 'Utah',
  },
  {
    key: 'vermont',
    name: 'Vermont',
    abbreviation: 'VT',
    t_abbreviation: 'Vt.',
  },
  {
    key: 'virginia',
    name: 'Virginia',
    abbreviation: 'VA',
    t_abbreviation: 'Va.',
  },
  {
    key: 'washington',
    name: 'Washington',
    abbreviation: 'WA',
    t_abbreviation: 'Wash.',
  },
  {
    key: 'west_virginia',
    name: 'West Virginia',
    abbreviation: 'WV',
    t_abbreviation: 'W.Va.',
  },
  {
    key: 'wyoming',
    name: 'Wyoming',
    abbreviation: 'WY',
    t_abbreviation: 'Wyo.',
  },
  {
    key: 'virgin_islands',
    name: 'Virgin Islands',
    abbreviation: 'VI',
    t_abbreviation: 'V.I.',
  },
]

export const isValidDesmosState = (state = '') => {
  const lowerCaseState = state?.toLocaleLowerCase() ?? ''
  return allStates.some((st) => {
    return (
      st.name.toLocaleLowerCase() === lowerCaseState ||
      st.abbreviation.toLocaleLowerCase() === lowerCaseState ||
      st.t_abbreviation.toLocaleLowerCase() === lowerCaseState
    )
  })
}

export const getDesmosConfig = (stateName = '', calculateMode) => {
  const {
    BASIC_STATE_DESMOS,
    SCIENTIFIC_STATE_DESMOS,
    GRAPHING_STATE_DESMOS,
    BASIC_DESMOS,
    SCIENTIFIC_DESMOS,
    GRAPHING_DESMOS,
  } = CALC_MODES

  const standardConfigs = {
    [BASIC_STATE_DESMOS]: standardBasic,
    [BASIC_DESMOS]: standardBasic,
    [GRAPHING_STATE_DESMOS]: standardGraphing,
    [GRAPHING_DESMOS]: standardGraphing,
    [SCIENTIFIC_STATE_DESMOS]: standardScientific,
    [SCIENTIFIC_DESMOS]: standardScientific,
  }

  if (
    !stateName ||
    ![
      BASIC_STATE_DESMOS,
      SCIENTIFIC_STATE_DESMOS,
      GRAPHING_STATE_DESMOS,
    ].includes(calculateMode)
  ) {
    return standardConfigs[calculateMode]
  }

  const state = allStates.find((st) => {
    return (
      st.name.includes(stateName) ||
      st.abbreviation.includes(stateName) ||
      st.t_abbreviation.includes(stateName)
    )
  })

  if (!state) {
    return standardConfigs[calculateMode]
  }

  switch (calculateMode) {
    case BASIC_STATE_DESMOS:
      return stateConfigBasic[state.key] || standardBasic
    case SCIENTIFIC_STATE_DESMOS:
      return stateConfigScientific[state.key] || standardScientific
    case GRAPHING_STATE_DESMOS:
      return stateConfigGraphing[state.key] || standardGraphing
    default:
      console.error(`config not defined for calcMode: ${calculateMode}`)
  }
}

export const getStateName = (stateName = '') => {
  if (!stateName) {
    return ''
  }

  const state = allStates.find((st) => {
    return (
      st.name.includes(stateName) ||
      st.abbreviation.includes(stateName) ||
      st.t_abbreviation.includes(stateName)
    )
  })
  if (!state) {
    return ''
  }

  return state.name
}
